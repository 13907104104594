<template>
  <v-container class="not-found">
    <not-found />
  </v-container>
</template>
<script>
import NotFound from "@/components/category/not-found.vue";
export default {
  name: "NotFoundView",
  components: [NotFound]
};
</script>
<style lang="scss">
.not-found {
  .text-vertical-center {
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .text-vertical-center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 18px 0;
    font: 900 35px;
  }
  .text-vertical-center h3 {
    margin: 0;
    padding: 5px 0;
    font: 800 22px;
  }
  .text-vertical-center h4 {
    margin: 0;
    padding: 0 0 30px 0;
    font: 400 20px;
  }
}
</style>
