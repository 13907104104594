<template>
  <div
    class="product-description pb-4"
    v-if="
      tabs.length > 0 ||
        (isAuthenticated && product.productInfos.RATE_ENABLED == 'true')
    "
  >
    <v-expansion-panels
      v-model="productDescriptionAccordion"
      accordion
      flat
      tile
      :focusable="selectedTab"
    >
      <!-- first panel: product description -->
      <v-expansion-panel
        v-for="tab in tabs"
        :key="tab.key"
        :class="tab.cssClass"
      >
        <v-expansion-panel-header
          hide-actions
          class="pa-3 px-sm-3 primary--text font-weight-bold"
        >
          <template v-slot:default="{ open }">
            <span class="font-weight-bold w-100" v-html="tab.title"></span>
            <v-icon color="primary" medium>
              <template v-if="open">$expandLess</template>
              <template v-else>$next</template>
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2">
          <div
            v-for="item in tab.items"
            :key="item.title"
            class="pb-4"
            :class="item.cssClass"
          >
            <h4
              v-if="item.title"
              class="mb-1 description-header text-uppercase font-weight-bold"
            >
              {{ item.title }}
            </h4>
            <div
              v-for="path in item.paths"
              :key="path.key"
              class="description-content"
              @click="clicked"
            >
              <component
                :is="path.template || 'DefaultTpl'"
                :content="path.content"
                :append="path.append"
              />
            </div>
          </div>
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>

      <!-- second panel and over -> product rating -->
      <v-expansion-panel
        v-if="isAuthenticated && product.productInfos.RATE_ENABLED == 'true'"
      >
        <v-expansion-panel-header
          hide-actions
          class="pa-3 px-sm-3 primary--text font-weight-bold"
        >
          <template v-slot:default="{ open }">
            <span class="font-weight-bold w-100">Recensione clienti</span>
            <v-icon color="primary" medium>
              <template v-if="open">$expandLess</template>
              <template v-else>$next</template>
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2">
          <ProductCustomerVotes
            class="my-3"
            :productId="product.productId"
            :key="product.productId"
          />
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import clickHandler from "~/mixins/clickHandler";

import ProductRating from "@/components/product/ProductRating.vue";
import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";
import DefaultTpl from "./templates/DefaultTpl.vue";
import tabsConfig from "/public/product/descriptionTabsConfig.json";

import { mapGetters } from "vuex";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";

export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    ProductRating,
    ProductCustomerVotes,
    DefaultTpl
  },
  mixins: [clickHandler],

  data() {
    return {
      // panelOpen: 0,
      selectedTab: null,
      productDescriptionTabs: tabsConfig
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    panelOpen: {
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                console.log(cells);
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      return tabs;
    },
    productDescriptionAccordion: {
      get() {
        return this.panelOpen;
      },
      set(value) {
        this.$emit("update:panelOpen", value);
      }
    }
  }
};
</script>

<style lang="scss">
.product-description {
  table {
    border-collapse: collapse;
  }
  table,
  tr,
  td,
  th {
    border: 1px solid var(--v-grey-base);
  }
  td,
  th {
    padding: 2px 6px;
  }
  th {
    background-color: var(--v-grey-lighten1);
  }

  .v-expansion-panels {
    .v-expansion-panel-header {
      font-size: 22px;
    }
  }

  .v-expansion-panel-content {
    color: $text-color;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-expansion-panels {
      .v-expansion-panel-header {
        font-size: 16px;
      }
      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          .description-header {
            font-size: 16px;
          }
          .description-content {
            font-size: 14px;
          }
        }
      }
      // .v-expansion-panel {
      //   border-bottom: 1px solid var(--v-grey-lighten1);
      // }
    }
  }
  .producer_info .text-h5 {
    font-weight: bold;
  }
}
</style>
